import React, { useState, useEffect, useRef } from "react";
import Assets from "@/components/Assets";
import axios from "axios";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard";
import { useDebounce } from "@uidotdev/usehooks";

function SharePodcasts({ sender, senderName, open, close, code }) {
  const [state, setState] = useState({
    name: "",
    email: "",
    errors: {
      message: "",
      type: null,
    },
    sender: sender,
    senderName: senderName,
    status: 0,
    opened: open,
  });
  const nameRef = useRef(null);
  const emailRef = useRef(null);

  const [copiedText, copy] = useCopyToClipboard();
  const [btnText, setBtnText] = useState("Copy Link");
  const [isEmailExist, setIsEmailExist] = useState(false);
  const debouncedEmail = useDebounce(state.email, 300);

  const handleCopy = (text) => () => {
    if (state.name !== "" && state.email !== "") {
      copy(text)
        .then(() => {
          setBtnText("Copied");
        })
        .catch((error) => {
          setBtnText("Failed to copy!");
        });
    } else {
      setState({
        ...state,
        errors: {
          message:
            "Whoops! 🫣 You need to fill out all the criteria to share this Contractor Podcast",
        },
      });

      if (state.name === "") {
        nameRef.current.focus();
      } else if (state.email === "") {
        emailRef.current.focus();
      }
      return false;
    }
  };

  useEffect(() => {

    async function getData(email) {
      await axios
        .post(process.env.REACT_APP_API_URL, {
          query: "getProfile",
          email: email,
        })
        .then((response) => {

          if(response?.data?.body[0]?.status === "success") {
            setIsEmailExist(true); 
          }else {
            setIsEmailExist(false);
          }
          
        })
        .catch((error) => {
          console.log(error);
          setIsEmailExist(false);
        });

    }

    
    if(String(debouncedEmail)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )) {
        setIsEmailExist(false);
        
     getData(debouncedEmail);
    }else {
      setIsEmailExist(false);
    }
  }, [debouncedEmail]);

  useEffect(() => {
    setState({
      ...state,
      errors: {
        message: "",
        type: null,
      },
    });
  }, [state.name, state.email]);

  //Validate each of the values
  function validate() {
    return [state.email, state.name].every((type) => {
      if (type === "") {
        setState({
          ...state,
          errors: {
            message:
              "Whoops! 🫣 You need to fill out all the criteria to share this Contractor Podcast",
            type: type,
          },
        });
        return false;
      }
      return true;
    });
  }

  function handleSubmit(e) {
    e.preventDefault();

    //If we don't have any empty values
    if (validate() && !isEmailExist) {
      //Validate the email
      const v = String(state.email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );

      //
      if (v) {
        axios
          .post(process.env.REACT_APP_EMAIL_URL, {
            query: "sharePodcast",
            email: state.email,
            name: state.name,
            sender: state.sender,
            senderName: state.senderName,
            link: `${
              process.env.REACT_APP_URL
            }/share?email=${sender}&category=Podcast&name=${state.name.replace(
              /\s+/g,
              "_"
            )}&code=${code}&details=postcast`,
          })
          .then(function (response) {
            if (response.data.body === "Email Sent") {
              setState({
                ...state,
                status: 1,
              });
            }
          })
          .catch(function (error) {
            console.log(error);
          });

        //Show Success
      } else {
        //Set it as an invalid email
        setState({
          ...state,
          errors: {
            message: "Whoops! 🫣 You need to input a valid email address",
            type: "email",
          },
        });
      }
    }
  }

  return (
    <div id="podcastShare" className="shareModal">
      <div className="Banner-Image-Wrapper">
        <img src={Assets["podcast-share"]} alt="podcast" />
      </div>

      <div className="share-header">
        <div>
          <h2>Contractor Evolution</h2>
          <h3>Share Contractor Evolution with a friend.</h3>
        </div>
      </div>

      <div className="content">
        {state.status === 1 && (
          <div className="success">
            <div className="icon">🙌</div>
            <h2>Thanks for sharing</h2>
            <p>
              Thanks for sharing BTA Content with a friend and helping a fellow
              contractor systemize their business.
            </p>
            <button className="button" onClick={close}>
              10 BTA Points Earned
            </button>
          </div>
        )}
        {state.status === 0 && (
          <>
            <form onSubmit={handleSubmit}>
              <div>
                <p className="fullwidth">
                  <strong>
                    Send your friend a podcast on a topic they actually care
                    about.
                  </strong>
                </p>
              </div>

              <div>
                <input
                  type="text"
                  name="name"
                  ref={nameRef}
                  value={state.name}
                  className={state.errors.type === "name" ? "error" : ""}
                  onChange={(e) => setState({ ...state, name: e.target.value })}
                  placeholder="Name *"
                />
                <input
                  type="email"
                  name="email"
                  ref={emailRef}
                  value={state.email}
                  className={state.errors.type === "email" ? "error" : ""}
                  onChange={(e) =>
                    setState({ ...state, email: e.target.value })
                  }
                  placeholder="Email *"
                />
              </div>

              <div>
                <input type="submit" className={`Button ${isEmailExist ? "disabled" : ""}`} value="Send Email" disabled={isEmailExist} />
                {state.errors.message && (
                  <p className="error">{state.errors.message}</p>
                )}

                {isEmailExist && (
                  <p className="error">Ahhh biscuits, it looks like you're sharing this resource with another Member. That's cool, sharing is caring, right? We just can't add points to your dashboard for this share—those are reserved for your non-BTA industry friends. ✌️</p>
                )}
              </div>
            </form>

            <div className="shareCopy">
              <input
                type="text"
                disabled
                value={`${
                  process.env.REACT_APP_URL
                }/share?email=${sender}&category=Podcast&name=${state.name.replace(
                  /\s+/g,
                  "_"
                )}&code=${code}&details=postcast`}
              />
              <input
                type="submit"
                className={`Button ${
                  state.name === "" || state.email === "" ? "disabled" : ""
                }`}
                value={btnText}
                onClick={handleCopy(
                  `${
                    process.env.REACT_APP_URL
                  }/share?email=${sender}&category=Podcast&name=${state.name.replace(
                    /\s+/g,
                    "_"
                  )}&code=${code}&details=postcast`
                )}
              />
            </div>

            <p className="copyCopy">
              Copy your personalized link and send it to a friend on whatever
              channel is best. They get great Contractor content, and you get
              BTA points.
            </p>

            <div className="modalFooter">
              <p>
                <strong>FUN FACT:</strong> prospects who have listened to 2+
                espidoes of Contractor Evolution are much more likely to trust
                and buy from BTA. The show has been a touchpoint with 85% of all
                new Members we've onboarded in recent years.
              </p>
              <p>
                <em>
                  This is a great resource to share with your referral friends
                  to consume while they're in their truck.
                </em>
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default SharePodcasts;
